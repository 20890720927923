

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');



@font-face {
  font-family: 'Albatross';
  src: url('./fonts/Test Söhne/test-soehne-buch.woff2') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Albatross light';
  src: url('./fonts/Test Söhne/test-soehne-leicht.woff2') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Albatross Mono';
  src: url('./fonts/Test Söhne Mono/test-soehne-mono-leicht.woff2') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  padding: 0;
  margin: 0;
}

.home-page {
  color: #F5F5F5; /* Whitish color, can adjust to your preference */
  font-family: 'Albatross',"Open Sans", sans-serif; /* Fallback to sans-serif if custom font fails */
}

.header, .menu, .cta-heading, .cta-link, .signup-form p {
  color: #F5F5F5; /* This will apply whiteish color to all text */
}

.gif-background {
  padding: 0;  
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*I Prefered this one ngl*/
  /* background: url('./images/bg.gif') no-repeat center center; /* Replace with your GIF path */
   background: url('./images/ArnoldZoom.gif') no-repeat center center; /*Replace with your GIF path */
  /* background: url('./images/seinfeld2.gif') no-repeat center center; Replace with your GIF path */
  background-size: cover;
  z-index: -1;
}

.gif-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(50, 50, 50, 0.75); /* Dark grayish tint */
  z-index: -1;
}


.container {
  opacity: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  position: fixed; /* Fixed positioning relative to the viewport */
  top: 0; /* Align to the top of the viewport */
  left: 0; /* Align to the left of the viewport */
  right: 0;
  width: 100%; /* Header spans the full width of the viewport */
  z-index: 1000; /* Ensure the header is above other content */

  display: flex;
  justify-content: space-between;
  padding: 1rem;
  padding-top: 1.2rem;
  border-bottom: 1px solid #ccc;
}

.footer {
  position: fixed;
  bottom: 0;
  height: 20rem;
  width: 100%;
  z-index: -1;
}

.xyz {
  color: #DFFF11;
}

.menu {
  margin-right: 1.9rem;
}

/* Padding to avoid content being hidden behind the fixed header */
.main-content {
  padding-top: 70px; /* Adjust to the height of your header */
  /* Other styles... */
}


.menu-link, .learn {
  color: #F5F5F5; /* Whiteish color for text */
  text-decoration: none; /* No underline by default */
  transition: text-decoration 0.3s; /* Smooth transition for the underline */
}

.learn {
  margin-right: 1rem;
}

.menu-link:hover, .learn:hover {
  text-decoration: underline; /* Underline on hover */
}

.main-content {
  display: flex;
  align-items: center; /* Ensure vertical centering */
  justify-content: flex-start; /* Aligns CTA to the start of the main axis */
  padding: 1rem;
  position: relative;
  height: calc(100% - 2rem); /* Adjust based on header height */
}

.signup-form {
  margin-top: 6.4rem; /* Increased distance from bottom */
  position: absolute; /* Use absolute positioning */
  right: 2rem; /* Position it 2rem from the right edge of the main-content */
  top: 50%; /* Center it vertically */
  transform: translateY(-50%); /* Adjust vertical centering */
  width: 22rem; /* Reduced width from 40% to 30% */
  padding: 1rem;
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  /*height: 200px;  Adjust height as needed */
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: rgba(255, 255, 255, 0); /* Slight transparency to show the background */

  padding-bottom: 4rem; /* Add padding to the bottom that is equal or greater than the height of the button */

}

.signup-form h2 {
  color: #F5F5F5;
  margin-bottom: 1rem;
}


.signup-form textarea {
  resize: none; 
  width: 100%;
}


.signup-form form {
  width: 100%; /* Set the form to fill the container */
}

.signup-form label {
  margin-top: 0.5rem;
  color: #F5F5F5; /* Whiteish color for labels for readability */
}


.signup-form input, 
.signup-form textarea,
.submit-button {
  font-family: 'Albatross',"Open Sans", sans-serif; /* Fallback to sans-serif if custom font fails */
  font-size: 0.9rem;
}


.signup-form input,
.signup-form textarea {
  width: 100%; /* Make inputs fill the width of the form */
  padding: 0.5rem;
  margin-top: 0.2rem; /* Space between the label and the input */
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}

.form-row {
  display: flex;
  align-items: center; /* This will vertically center the items in the flex container */
  margin-bottom: 1rem;
}

.form-label {
  margin-right: 0.1rem;
  margin-bottom: 0.3rem;
  white-space: nowrap; /* Prevents the label from wrapping */
}

input[type="text"],
input[type="email"] {
  flex-grow: 1; /* Allows input to fill the space */
  margin-left: 0.5rem; /* Adds some space between label and input */
}

.submit-button {
  /*padding: 0.5rem 1rem; /* Same padding as the CTA link */
  padding: 0.4rem 0.8rem; /* Padding inside the border */
  border-radius: 3px; /* Same border-radius as the CTA link for rounded corners */
  /*border: 1px solid rgba(25, 25, 25, 100); /* Same border style as the CTA link */
  /*background-color: rgba(25, 25, 25, 100); */
  border: 1px solid #F5F5F5; /* Same border style as the CTA link */
  background-color: transparent; /* Start with a transparent background */
  color: #F5F5F5; /* Whiteish color text to match the CTA link */
  cursor: pointer; /* Hand cursor on hover to indicate a clickable button */
  font-size: 1rem; /* Match the font size of the CTA link */
  /*transition: background-color 0.3s, color 0.3s; /* Smooth transition for background and text color */
  text-align: center; /* Center the text inside the button */
  display: inline-block; /* Allows for alignment similar to the CTA link */
  text-decoration: none; /* No underline by default */
  margin-top: 0.5rem; /* Spacing above the button, adjust as needed */
  width: auto; /* Adjust the width as needed or keep it auto */
  
  position: absolute; /* Position the button absolutely within the .signup-form */
  right: 1rem; /* Distance from the right border of the .signup-form */
  bottom: 1rem; /* Distance from the bottom border of the .signup-form */
  width: 6rem;
  max-height: 2.6rem;
  overflow: hidden;
  white-space: nowrap;
}

.signup-form button:hover {
  border: 1px solid #F5F5F5; /* Same border style as the CTA link */
  color: rgba(25, 25, 25, 100); 
  background-color: #F5F5F5; /* Background color on hover */
}

.submit-button.submitted, .submit-button.submitted:hover  {
  border: 1px solid #DFFF11; /* Same border style as the CTA link */
  color: rgba(25, 25, 25, 100); 
  background-color: #DFFF11; /* Background color on hover */
  text-overflow: ellipsis;
  width: 16rem;
  transition: border 1s, color 1s, background-color 1s, width 1s;
  /* transform: scale(1.1); */
}



.cta-container {
  position: absolute;
  bottom: 3.4rem; /* Increased distance from bottom */
  left: 3rem; /* Increased distance from left */
  flex: 0 0 40%; /* Flex basis of 40%, does not grow or shrink */
  max-width: calc(60% - 4rem); /* Ensure it doesn't overlap the form */
}


.cta-heading {
  font-size: 3rem; /* Maintain your size or adjust as necessary */
  font-weight: 400; /* This is normally 'normal' weight, less bold than the default */
  color: #F5F5F5; /* Assuming you are keeping the whitish color */

  
  margin-bottom: 1.5rem; /* Removes default margin */
}

.cta-link, .cta-link:active, .cta-link:visited {
  color: #F5F5F5;
  text-decoration: none; /* Removes underline */
  display: inline-block; /* Allows width and height to be set */
  margin-top: 0rem; /* Space between heading and link */
  margin-left: 0.5rem; /* Moves the link slightly to the right */
  /*padding: 0.5rem 1rem; /* Padding inside the border */
  padding: 0.3rem 0.7rem; /* Padding inside the border */
  border: 1px solid #F5F5F5; /* Border color */
  border-radius: 3px; /* Rounded corners for the border */
  /*transition: background-color 0.3s; /* Smooth transition for background fill */
}

.cta-link:hover {
 /* border: 1px solid #DFFF11; /* Same border style as the CTA link */
  color: rgba(25, 25, 25, 100); 
  background-color: #DFFF11; /* Background color on hover */
}

.cta-link:hover {
  color: rgba(25, 25, 25, 100); 
  background-color: #F5F5F5; /* Background color on hover */
}




.home-page {
  min-height: 200vh; /* Example height to allow for scrolling */
}

.darkbg {
  margin: 0;
  padding: 0;

  /*background-color: rgb(25, 25, 25); /* Background color as per your mockup */
  /*background-color: rgb(0, 0, 0); /* Background color as per your mockup */
  /* transition: opacity 0.1s ease-in-out;  */
}

.scrollable-content-section {
  /* margin: 1rem; */

  display: flex;
  justify-content: space-between; /* Space out the text and image */
  /* align-items: center; /*Vertically aligns content */
  color: #f5f5f5; /* Text color */
  height: 100vh;

  
}

/* State of the section when it's in view */
/* .scrollable-content-section.in-view {
  opacity: 1; /* Fully visible when in view 
  display: flex;
} */




.image-content {
  padding: 1rem;
  padding-top: 6rem;
  height: 78vh;
  /*flex-basis: 50%;  Adjust width as necessary */
  /* Style your image container if needed */

  margin-right: 2rem;
}

.image-content img {
  width: 100%; /* Width of the image will follow the container's width */
  height: 100%; /* Height of the image will follow the container's height */
  object-fit: cover; /* The image will cover the entire container */
  border-radius: 3px;
}


.text-content {
  margin: auto;
  margin-top: 5rem;
  margin-left: 2rem;
  margin-right: 2rem;
  flex-basis: 50%; /* Adjust width as necessary */
  /* Add padding or margins as needed for spacing */

  background-color: #1E1E1E;
  
  padding: 1.5rem;
  border: #ccc solid 1px;
  border-radius: 4px;
}


/* Style for the paragraph in the content section */
.scrollbody {
  font-family: 'Albatross light',"Open Sans", sans-serif; /* Fallback to sans-serif if custom font fails */
  letter-spacing: 0.05rem;
  margin-top: 1.5rem;
  padding: 0rem;
  font-size: 1rem; /* Large font size for heading */
  /* Additional paragraph styles as needed */
  
}

.scrollbody a, .scrollheading a, .fuckyoureact{
  color: #DFFF11;
  text-decoration: none;
}


.scrollbody a:visited{
  color: #DFFF11;
  text-decoration: none;
}

/* Style for the main heading in the content section */
.scrollheading{
  font-family: 'Albatross Mono',"Open Sans", sans-serif; /* Fallback to sans-serif if custom font fails */
  letter-spacing: 0.05rem;

  margin-top: 0rem;
  padding: 0rem;

  font-size: 2rem; /* Large font size for heading */
  margin-bottom: 0.5rem; /* Spacing between heading and paragraph */
}



