

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');



@font-face {
  font-family: 'Albatross';
  src: url('../../fonts/Test Söhne/test-soehne-buch.woff2') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Albatross Mono light';
  src: url('../../fonts/Test Söhne Mono/test-soehne-mono-leicht.woff2') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Albatross Mono';
  src: url('../../fonts/Test Söhne Mono/test-soehne-mono-buch.woff2') format('truetype');
  font-weight: normal;
  font-style: normal;
}


.containerStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 32vw;
    width: 43vw;
    /* margin: auto; */
    box-sizing: border-box;
  }
  
  .webcamContainerStyle {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    overflow: hidden;
    background-color: #000;
  }
  
  .buttonContainerStyle {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 0.2rem;
    margin-left: 1px;
    margin-right: 1px;
  }
  
  .buttonStyle {
    flex: 1;
    border: none;
    cursor: pointer;
    border-radius: 3px;
    width: 33%;
    font-size: 1.1rem;
    padding: 0.4rem 0.7rem;
    border: 1px solid rgb(20, 20, 20);
    color: rgb(20, 20, 20);
    font-family: 'Albatross',"Open Sans", sans-serif; /* Fallback to sans-serif if custom font fails */
  }
  
  .middleButton {
    margin: 0 0.2rem;
    width: 33%;
  }
  
  /* For the video portion*/
  .altText {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #000;
  }

  .webcamStyle {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }