.settings {
    position: fixed;
    top: 0;
    right: 0;
    width: 50%; 
    height: 100%;
    background: white;
    transform: translateX(100%);
    transition: transform 0.8s ease-out;
    /* box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1); */
    padding: 20px;
    box-sizing: border-box;
    z-index: 999;

    padding: 2rem;
    padding-right: 5rem;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.settings.open {
    transform: translateX(0);
    transition: transform 0.8s ease-out;
}


.buttonsportion {
    display: flex;
    margin-top: 0.8rem;
    justify-content: space-between;
    margin-left: 70%;
    /* width: 20%; */
}

.SettingsButtonStyle {

    margin: 0;

    cursor: pointer;
    border-radius: 3px;
    
    font-size: 1.1rem;
    padding: 0.4rem 0.7rem;

    border: 1px solid rgb(20, 20, 20);
    color: rgb(20, 20, 20);
    /* font-family: 'Albatross',"Open Sans", sans-serif; Fallback to sans-serif if custom font fails */
}
