@font-face {
    font-family: 'Albatross';
    src: url('../../fonts/Test Söhne/test-soehne-buch.woff2') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Albatross light';
    src: url('../../fonts/Test Söhne/test-soehne-leicht.woff2') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Albatross light italic';
    src: url('../../fonts/Test Söhne/test-soehne-leicht-kursiv.woff2') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Albatross Mono';
    src: url('../../fonts/Test Söhne Mono/test-soehne-mono-leicht.woff2') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

.loglist {
    /* margin: auto; */

    border: 1px solid #ccc;
    border-radius: 3px;
}

.log {
border: 1px solid #ccc;
}
  
.logtitle {
    cursor: pointer;
    background-color: #f5f5f5;
    font-weight: bold;
    border-radius: 3px;
    font-family: 'Albatross',"Open Sans", sans-serif; /* Fallback to sans-serif if custom font fails */
    
    
    display: flex;
    justify-content: space-between;
    padding: 0.6rem;
    padding-left: 1rem;
    font-size: 1rem;
}

.logtitle p {
    margin: 0;
    margin-top: 0.05rem;
}

.buttonContainer {
    /* float: right; Aligns content to the right */
    display: inline-block;
  }

.delbutton {
  font-weight: bold;
  font-size: 0.8rem;
  padding: 0.3rem 0.7rem; /* Padding inside the border */

  border-radius: 3px; /* Same border-radius as the CTA link for rounded corners */
  border: 1px solid #FF3030; /* Same border style as the CTA link */
  color: #FF3030;

  cursor: pointer; /* Hand cursor on hover to indicate a clickable button */
}

.delbutton:hover {
    background-color: #FF3030; /* Slightly darker shade on hover */
    color: #f5f5f5;
    border-color: #f5f5f5;
  }
  
.transcript {
    background-color: #fff;
    padding: 0.6rem;
    padding-bottom: 0.4rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.transcript p {
    margin: 0;
    margin-bottom: 0.4rem;
    font-size: 1rem;
}

.speaker1 {
    color: blue;
    font-family: 'Albatross Mono',"Open Sans", sans-serif; /* Fallback to sans-serif if custom font fails */
}

.speaker2 {
    color: red;
    font-family: 'Albatross Mono',"Open Sans", sans-serif; /* Fallback to sans-serif if custom font fails */
}
 
.retrospectively {
    /* text-indent: 1rem; */
    margin-left: 1rem;
    color: green;
    font-family: 'Albatross light italic',"Open Sans", sans-serif; /* Fallback to sans-serif if custom font fails */
}