@font-face {
    font-family: 'Albatross';
    src: url('./fonts/Test Söhne/test-soehne-buch.woff2') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Albatross light';
    src: url('./fonts/Test Söhne/test-soehne-leicht.woff2') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Albatross light italic';
    src: url('./fonts/Test Söhne/test-soehne-buch-kursiv.woff2') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Albatross Mono';
    src: url('./fonts/Test Söhne Mono/test-soehne-mono-buch.woff2') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Albatross Mono Heavy';
    src: url('./fonts/Test Söhne Mono/test-soehne-mono-kraftig.woff2') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  
  @font-face {
    font-family: 'Albatross Mono Light';
    src: url('./fonts/Test Söhne Mono/test-soehne-mono-leicht.woff2') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

.app {
  background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: 'Albatross',"Open Sans", sans-serif; /* Fallback to sans-serif if custom font fails */
  }
  
  .titlesec {
    text-align: left;
    margin: 0.5rem;
    margin-left: 0rem;
    background-color: #DFFF11;
    display:inline-block;

    font-size: 2rem;
    font-family: 'Albatross Mono',"Open Sans", sans-serif; /* Fallback to sans-serif if custom font fails */ 
    padding: 0 0.5rem; 
  }
  
  
  .media-capture-section {
    margin: 1rem;
    flex: 3; /* Adjust the flex ratio based on the desired width */
    
    display: flex;
    flex-direction: column;
  }
  
  .mediacol {
    margin: auto;
    margin-top: 0;
    /* margin-left: 2rem; */
    display:inline-block;

    
    padding: 0.8rem;
    border-radius: 5px;
    border: 1px solid #ccc;
  }

  .how-to-use {
    margin-top: 1rem;
    border-radius: 3px;
    border: 1px solid #ccc;
    /* display:inline-block;  */
    padding: 1rem;
    padding-left: 1.2rem;
  }

  .how-to-use p, h2, h3{
    margin: 0;
  }

  .how-to-use p {
    margin-top: 1rem;
    text-indent: 1rem;
  }

  .how-to-use h3 {
    margin-top: 1rem;
  }
  
  .sidebar {
    flex: 1; /* Adjust the flex ratio based on the desired width */
    display: flex;
    flex-direction: column;
    margin: auto;
  }
  
  .logs-section {
    padding: 0.6rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }


  .feedback {
    border: 1px solid #ccc;
    border-radius: 3px;

    font-size: 1.4rem;
    padding: 0.4rem 0.8rem; /* Padding inside the border */

   
    font-family: 'Albatross light italic',"Open Sans", sans-serif; /* Fallback to sans-serif if custom font fails */
  }


  .live-feedback-section {
    border: 1px solid #ccc;
    border-radius: 5px;
    
    padding: 1rem;
    padding-left: 0.6rem;
    margin-bottom: 1rem;
  }

  .live-feedback-section h2, .logs-section h2{
    margin: 0;
    margin-left: 0.6rem;
  }

  .logs-section h2{
    margin-top: 0.6rem;
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
  }

  
  /* Add responsive design or additional styling as needed */
  
  .app {
    display: flex;
  }
  

  .logs {
    width: 100%; /* Takes up half of the screen */
    margin: 1rem;
    margin-left: 0;
    display: flex;
    flex-direction: column;
  }
  
  /* Style for Live Feedback and Logs components stays the same */
  

  .prompt {
    font-size: 1rem;
    font-family: 'Albatross Mono',"Open Sans", sans-serif; /* Fallback to sans-serif if custom font fails */ 

    
    width: 100%; /* Make inputs fill the width of the form */
    padding: 0.5rem;
    margin-top: 0.2rem; /* Space between the label and the input */
    border: 1px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box; 
    resize: none;
  }

  .settingstext {
    font-size: 1.1rem;
    font-family: 'Albatross',"Open Sans", sans-serif; /* Fallback to sans-serif if custom font fails */ 
  }